<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-row no-gutters>
        <v-col cols="12" align="center">
          <!-- loaded -->
          <v-slider class="mwm-slider" v-model="percentage" color="#F1B51D" track-color="#EDEDED66" @click.native="setPosition()" hide-details />
          <audio id="player" ref="player" v-on:ended="ended" v-on:canplay="canPlay" :src="file"></audio>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" align="center">
          <span class="mwm-player-elapsed">{{ currentTime }}</span>
          <span class="mwm-player-bar"> | </span>
          <span class="mwm-player-total">{{ duration }}</span>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col cols="12" align="center">
          <!-- :disabled="!loaded" -->
          <v-btn outlined icon class="ma-2" :color="color" @click.native="playing ? pause() : play()">
            <v-icon v-if="!playing || paused">play_arrow</v-icon>
            <v-icon v-else>pause</v-icon>
          </v-btn>
          <v-btn outlined icon class="ma-2" :color="color" @click.native="stop()">
            <v-icon>stop</v-icon>
          </v-btn>
          <v-btn outlined icon class="ma-2" :color="color" @click.native="mute()">
            <v-icon v-if="!isMuted">mdi-volume-high</v-icon>
            <v-icon v-else>mdi-volume-mute</v-icon>
          </v-btn>
          <!-- <v-btn outlined icon class="ma-2" :color="color" @click.native="loaded ? download() : reload()" v-if="!loaded">
            <v-icon>mdi-refresh</v-icon>
          </v-btn>
          <v-btn outlined icon class="ma-2" :color="color" @click.native="loaded ? download() : reload()" v-if="loaded && downloadable">
            <v-icon>mdi-download</v-icon>
          </v-btn> -->
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
const formatTime = second => new Date(second * 1000).toISOString().substr(11, 8);
export default {
  name: 'vuetify-audio',
  props: {
    flat: {
      type: Boolean,
      default: false
    },
    file: {
      type: String,
      default: null
    },
    autoPlay: {
      type: Boolean,
      default: false
    },
    ended: {
      type: Function,
      default: () => {},
    },
    canPlay: {
      type: Function,
      default: () => {},
    },
    color: {
      type: String,
      default: '#F1B51D'
    },
    downloadable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    duration() {
      return this.audio ? formatTime(this.totalDuration) : ''
    },
  },
  data () {
    return {
      firstPlay: true,
      isMuted: false,
      loaded: false,
      playing: false,
      paused: false,
      percentage: 0,
      currentTime: '00:00:00',
      audio: undefined,
      totalDuration: 0,
      playerVolume: 0.5
    }
  },
  methods: {
    setPosition () {
      this.audio.currentTime = parseInt(this.audio.duration / 100 * this.percentage, 10);
    },
    stop () {
      this.audio.pause()
      this.paused = true
      this.playing = false
      this.audio.currentTime = 0
    },
    play () {
      if (this.playing) return
      this.audio.play().then(() => {
        this.playing = true
      })
      this.paused = false
    },
    pause () {
      this.paused = !this.paused;
      if (this.paused) {
        this.audio.pause()
      } else {
        this.audio.play()
      }
    },
    download () {
      this.audio.pause()
      window.open(this.file, 'download')
    },
    mute () {
      this.isMuted = !this.isMuted
      this.audio.muted = this.isMuted
    },
    reload () {
      this.audio.load();
    },
    handleLoaded () {
      if (this.audio.readyState >= 2) {
        if (this.audio.duration === Infinity) {
          // Fix duration for streamed audio source or blob based
          // https://stackoverflow.com/questions/38443084/how-can-i-add-predefined-length-to-audio-recorded-from-mediarecorder-in-chrome/39971175#39971175
          this.audio.currentTime = 1e101;
          this.audio.ontimeupdate = () => {
            this.audio.ontimeupdate = () => {}
            this.audio.currentTime = 0
            this.totalDuration = parseInt(this.audio.duration, 10)
            this.loaded = true;
          }
        } else {
          this.totalDuration = parseInt(this.audio.duration, 10)
          this.loaded = true
        }
        if (this.autoPlay) this.audio.play()
      } else {
        throw new Error('Failed to load sound file')
      }
    },
    handlePlayingUI () {
      this.audio.volume = this.playerVolume
      this.percentage = this.audio.currentTime / this.audio.duration * 100
      this.currentTime = formatTime(this.audio.currentTime)
      this.playing = true
    },
    handlePlayPause (e) {
      if (e.type === 'play' && this.firstPlay) {
        // in some situations, audio.currentTime is the end one on chrome
        this.audio.currentTime = 0;
        if (this.firstPlay) {
          this.firstPlay = false;
        }
      }
      if (e.type === 'pause' && this.paused === false && this.playing === false) {
        this.currentTime = '00:00:00'
      }
    },
    handleEnded () {
      this.paused = false;
      this.playing = false;
    },
    init () {
      this.audio.addEventListener('timeupdate', this.handlePlayingUI);
      this.audio.addEventListener('loadeddata', this.handleLoaded);
      this.audio.addEventListener('pause', this.handlePlayPause);
      this.audio.addEventListener('play', this.handlePlayPause);
      this.audio.addEventListener('ended', this.handleEnded);
    },
  },
  mounted () {
    this.audio = this.$refs.player;
    this.init();
  },
  beforeDestroy () {
    this.audio.removeEventListener('timeupdate', this.handlePlayingUI)
    this.audio.removeEventListener('loadeddata', this.handleLoaded)
    this.audio.removeEventListener('pause', this.handlePlayPause)
    this.audio.removeEventListener('play', this.handlePlayPause)
    this.audio.removeEventListener('ended', this.handleEnded);
  }
}
</script>
<style scoped>
.mwm-player-elapsed {
  color: white;
  font-style: normal;
  font-weight: 500;
  font-size: 11.0358px;
  line-height: 13px;
  /* identical to box height */
  text-align: right;
  opacity: 0.6;
}
.mwm-player-bar {
  color: #FBB237;
}
.mwm-player-total {
  color: white;
  font-style: normal;
  font-weight: 500;
  font-size: 11.0358px;
  line-height: 13px;
  opacity: 0.6;
}
</style>
