import { mwmEdjingBackendMixesInstance } from '@/config/api';

export default {
  getMix(payload) {
    const path = `/mixes/${payload.mix_id}`;

    return mwmEdjingBackendMixesInstance.get(path)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response));
  },
};
