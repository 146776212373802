<template>
  <v-row no-gutters justify="center" v-if="currentMix != null" class="mwm-dj-mixes-background">
    <v-col lg="3" md="5" sm="8" cols="8">
      <v-card v-if="!isMobile" dark class="mwm-dj-card-content">
        <!-- <v-row no-gutters justify="center" class="mwm-dj-card-header-content">
          <v-col cols="12">
            <v-row no-gutters align="center">
              <v-col cols="3" align="center">
                <v-avatar>
                  <img src="https://cdn.vuetifyjs.com/images/john.jpg" />
                </v-avatar>
              </v-col>
              <v-col cols="9">
                <v-row no-gutters>
                  <v-col cols="12">
                    <div class="mwm-dj-title">{{currentMix.user_name}}</div>
                    <div class="mwm-dj-subtitle">is using edjing Mix. Join him now!</div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
        </v-row> -->
        <v-row no-gutters justify="center">
          <v-col cols="auto">
            <v-row no-gutters justify="center" align="center">
              <v-col cols="auto">
                <span class="mwm-player-top-text mr-1">MIX MADE WITH</span>
              </v-col>
              <v-col cols="auto">
                <v-img class="ml-1" :src="'/static/img/logo_edjing.png'" />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row class="mt-5" no-gutters justify="center">
          <v-col cols="auto">
            <v-img id="create" v-if="currentMix.cover_url !== ''" min-width="150" :src="currentMix.cover_url" />
            <v-img v-else class="mwm-img-radius" :src="'/static/img/51e7c1a9ac7f485c45ee355721b87db2440bdbfb1.png'" />
          </v-col>
        </v-row>
        <v-row class="mt-3" no-gutters justify="center">
          <v-col cols="auto" align="center">
            <span class="text-h5 font-weight-bold white--text">{{currentMix.name}}</span>
          </v-col>
        </v-row>
        <v-row no-gutters justify="center">
          <v-col cols="auto">
            <span class="white--text">{{currentMix.user_name}}</span>
          </v-col>
        </v-row>
        <v-row v-if="currentMix" class="mwm-download-buttons-container" no-gutters justify="center">
          <v-col cols="10">
            <audio-player :file="currentMix.mix_url" />
          </v-col>
        </v-row>
      </v-card>
      <v-row v-else no-gutters justify="center" class="mwm-dj-card-mobile-content">
        <v-col cols="12" align="center">
          <v-row class="mt-2" no-gutters justify="center">
            <v-col cols="auto">
              <v-row no-gutters justify="center" align="center">
                <v-col cols="auto">
                  <span class="mwm-player-top-text mr-1 white--text">MIX MADE WITH</span>
                </v-col>
                <v-col cols="auto">
                  <v-img class="ml-1" :src="'/static/img/logo_edjing.png'" />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row class="mt-3" no-gutters justify="center">
            <v-col cols="auto">
              <v-img id="create" v-if="currentMix.cover_url !== ''" min-width="150" :src="currentMix.cover_url" />
              <v-img v-else class="mwm-img-radius" :src="'/static/img/51e7c1a9ac7f485c45ee355721b87db2440bdbfb1.png'" />
            </v-col>
          </v-row>
          <v-row class="mt-3" no-gutters justify="center">
            <v-col cols="auto" align="center">
              <span class="text-h5 font-weight-bold white--text">{{currentMix.name}}</span>
            </v-col>
          </v-row>
          <v-row no-gutters justify="center">
            <v-col cols="auto">
              <span class="white--text">{{currentMix.user_name}}</span>
            </v-col>
          </v-row>
          <v-row v-if="currentMix" class="mwm-download-buttons-container" no-gutters justify="center">
            <v-col cols="10">
              <audio-player :file="currentMix.mix_url" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
  <v-row v-else no-gutters>
    <v-col cols="12">
      <v-row no-gutters justify="center">
        <v-col v-if="isMobile" cols="8">
          <v-img :src="'/static/img/edjing_artwork.png'" />
        </v-col>
        <v-col v-else cols="4">
          <v-img :src="'/static/img/edjing_artwork.png'" />
        </v-col>
      </v-row>
      <v-row class="mwm-download-buttons-container" no-gutters justify="center">
        <v-col cols="auto">
          <v-btn text>
            <v-img width="135" @click="goToEdjing" :src="'/static/img/download_appstore.svg'" />
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-btn text>
            <v-img width="150" @click="goToEdjing" :src="'/static/img/download_playstore.svg'" />
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

import AudioPlayer from '@/components/AudioPlayer.vue'

export default {
  components: {
    'audio-player': AudioPlayer,
  },
  data: () => ({

  }),
  methods: {
    goToEdjing () {
      const url = 'https://edjing-website-landing-page.onelink.me/Yx8k/100d32e5';
      const win = window.open(url, '_blank')
      win.focus()
    }
  },
  computed: {
    ...mapGetters([
      'isMobile',
      'currentMix'
    ]),
  },
  mounted () {
    if (this.$route.params.mix_id != null) {
      this.$store.dispatch('mixesGetMix', { mix_id: this.$route.params.mix_id })
    }
  }
};
</script>

<style scoped lang="scss">
.mwm-img-radius {
  border-radius: 50%;
}
.mwm-download-buttons-container {
  margin-top: 1rem;
}
.mwm-dj-card-header-content {
  background-color: white;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.mwm-dj-title {
  color: black;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 19px;
}
.mwm-dj-subtitle {
  color: black;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}
.mwm-dj-card-content {
  padding-top: 3rem;
  padding-bottom: 3rem;
  margin-top: 10rem;
  border-radius: 18px!important;
  background-image: url("/static/img/bg_card_mobile.png");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
}
.mwm-player-top-text {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
}
.mwm-dj-mixes-background {
  height: 100vh;
  background-image: url("/static/img/bg_desktop.png");
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  background-size: cover; /* Resize the background image to cover the entire container */
  align-items: center;
}
</style>
