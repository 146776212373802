import { mwmEdjingTidalAnalyticsInstance } from '@/config/api';

export default {
  sendEvent(payload) {
    return mwmEdjingTidalAnalyticsInstance.post('/tidal-analytics/', payload)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response));
  },
  getTidalCode() {
    return mwmEdjingTidalAnalyticsInstance.get('/tidal-codes/unused')
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response));
  },
  burnTidalCode(code) {
    return mwmEdjingTidalAnalyticsInstance.get(`/tidal-codes/burn?code=${code}`)
      .then(response => Promise.resolve(response))
      .catch(error => Promise.reject(error.response));
  },
};
