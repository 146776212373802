<template>
  <v-row no-gutters class="mwm-faq-container" justify="space-around">
    <v-col :cols="isMobile ? 11 : 4">
      <v-row v-if="!isMobile" no-gutters>
        <span class="mwm-faq-page-title">edjing Mix - FAQs</span>
      </v-row>
      <v-row no-gutters>
        <v-expansion-panels multiple dark :class="isMobile ? 'mb-8' : 'mb-15'">
          <span class="mwm-faq-section-title mb-5">Setup, guide, tips</span>
          <v-expansion-panel v-for="item in setupGuideTipsItems" :key="item.question">
            <v-expansion-panel-header>
              <span class="mwm-faq-title">{{ item.question }}</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <span class="mwm-faq-text" v-html="item.answer" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-expansion-panels multiple dark :class="isMobile ? 'mb-8' : 'mb-15'">
          <span class="mwm-faq-section-title mb-5">Troubleshooting</span>
          <v-expansion-panel v-for="item in troubleshootingItems" :key="item.question">
            <v-expansion-panel-header>
              <span class="mwm-faq-title">{{ item.question }}</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <span class="mwm-faq-text" v-html="item.answer" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <v-expansion-panels multiple dark :class="isMobile ? 'mb-8' : 'mb-15'">
          <span class="mwm-faq-section-title mb-5">Account, subscription</span>
          <v-expansion-panel v-for="item in accountItems" :key="item.question">
            <v-expansion-panel-header>
              <span class="mwm-faq-title">{{ item.question }}</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <span class="mwm-faq-text" v-html="item.answer" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>

        <!-- <v-expansion-panels multiple dark :class="isMobile ? 'mb-8' : 'mb-15'">
          <span class="mwm-faq-section-title mb-5">Security and Policies</span>
          <v-expansion-panel v-for="item in securityItems" :key="item.question">
            <v-expansion-panel-header>
              <span class="mwm-faq-title">{{ item.question }}</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <span class="mwm-faq-text" v-html="item.answer" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels> -->

        <!-- <v-expansion-panels multiple dark :class="isMobile ? 'mb-8' : 'mb-15'">
          <span class="mwm-faq-section-title mb-5">edjing Mix community</span>
          <v-expansion-panel v-for="item in communityItems" :key="item.question">
            <v-expansion-panel-header>
              <span class="mwm-faq-title">{{ item.question }}</span>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <span class="mwm-faq-text" v-html="item.answer" />
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels> -->
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';


export default {
  components: {

  },
  data: () => ({
    setupGuideTipsItems: [
      {
        question: 'What are the main features of edjing Mix app?',
        answer: 'edjing Mix app offers a variety of features to help users create unique mixes, including FX, Loop, precueing, BPM analysis, pitch, smart E.Q and hot cues.<br/><br/>It also provides guided lessons to help users learn the basic tools of a pro DJ in no time and a deck section where they can discover the different actions a DJ performs on a deck.<br/><br/>Additionally, the app offers Automix and Sync features to create seamless mixes. edjing Mix is compatible with a wide range of devices, including iOS, Android, and Windows devices.'
      },
      {
        question: 'Why can\'t I upload some tracks from Apple Music to edjing Mix?',
        answer: 'You cannot upload music from Apple Music to edjing Mix because it is DRM-protected and cannot be accessed by the app. However, if the music belongs to you and is stocked in your iTunes library, you should be able to use it. Unfortunately, you cannot use YouTube, Deezer and Spotify with edjing Mix either, as they do not allow third-party apps to use their content.<br/><br />edjing Mix does offer an extensive music library, including your own mp3s, streaming sources like iTunes or SoundCloudGo +, and tracks from cloud storage platforms such as Google Drive or Dropbox. If you have feedback or suggestions, it is best to contact the respective support teams for YouTube, Apple Music, Deezer, and Spotify.'
      },
      {
        question: 'What are the DJ lessons offered in edjing Mix?',
        answer: 'In edjing Mix, you can become a professional DJ with exclusive lessons from the best DJ teachers out there. These include learning videos, interactive tutorials, and more.'
      },
      {
        question: 'How do I record a set?',
        answer: 'To record a mix, click the red "Record" button at the bottom of the Menu screen. It will start recording a screen video of your mix.<br/><br/>To stop the recording, click on the button replacing the Menu Button. <br/><br/>On iOS, you can export the Audio or the Video of your mix. Please make sure you gave the necessary authorization to our application. Be sure to save it in your Camera Roll or anywhere else before leaving. We don\'t save your mix in the app.'
      },
      {
        question: 'How to connect my DDJ 200 to edjing Mix?',
        answer: '<ol> <li>Connect the turntable to any power source: wall socket, USB socket, etc.</li> <li>Open the edjing app (make sure it is up to date)</li> <li>Go to the app settings (at the top of the interface)</li> <li>Scroll and click the "Connect to DDJ 200" menu</li> <li>The turntable should appear in the menu, otherwise click on "Search" at the top right</li> <li>Once the board is recognized, click on it and click on "connect" (from this same menu you can also disconnect it, if necessary)</li> <li>Time to mix!</li></ol><br/>Finally: Have you checked your audio settings? When you activate the DDJ app, it automatically enables pre-cueing, which may be causing the issue. You can try disabling it in your audio settings (in the app) to see if that helps.<br/><br/>Additionally, we recommend you refer to this information before attempting to connect your controller again. See the following link to <a  target="_blank" href="https://docs.pioneerdj.com/Manuals/DDJ_200_DRI1596C_manual/?_ga=2.58495843.813706337.1692263793-1824556369.1683647968">Pioneer controller’s manual</a>.<br /><br/>If you continue to experience issues after referring to Pioneer\'s guidance, we kindly ask you to send us a video of you trying to get connected to the DDJ 200. Do not hesitate to make comments while filming.  That will help us better understand the issue you are facing.'
      }
    ],
    troubleshootingItems: [
      {
        question: 'I have trouble using the app. What should I do?',
        answer: 'Please make sure your app is updated. If it is and you think you still have an issue, our team is interested in finding its cause. Please send us an email to support@edjing.com with the info about your app and OS versions, and the model of your device.<br/><br/>While we are investigating your issue, allow us to <b>share some steps to increase the stability of any app</b>:<br/> <ol><li>Checking your internet connection.</li> <li>Ensuring that you have the latest version of the app installed from the store.</li> <li>Restarting the app by closing it and making sure it does not stay open in the background.</li> <li>Restarting your device.</li> <li>Uninstalling and reinstalling the app (<b>please be aware that if you have not created an account and you uninstall the app, you will lose your creations and what you developed in our app, etc. We recommend creating an account before doing this</b>).</li> <li>Checking that app permissions are all set.</li> <li>Ensuring that your OS is updated.</li><ol>'
      }
    ],
    accountItems: [
      {
        question: 'How do I cancel my subscription to the app?',
        answer: 'For Android, please follow these steps:<br/><ol><li>On your <b>Android</b> phone or tablet, open the Google Play Store.</li> <li>Tap Menu > Subscriptions.</li> <li>Tap the subscription you want to cancel.</li> <li>Tap Cancel subscription.</li> <li>Follow the onscreen instructions.</li></ol><br/>For <b>iOS</b>, please follow these steps:<br/> <ol> <li>Go to the Settings of your iPhone/iPad device> [your name] > iTunes & App Store.</li> <li>Tap your Apple ID at the top of the screen.</li> <li>Tap View Apple ID. You might need to authenticate your Apple ID.</li> <li>Tap Subscriptions.</li> <li>Tap the subscription that you want to manage.</li> <li>Use the options to manage your subscription. You can choose a different subscription offering, or tap Cancel Subscription to cancel your subscription. If you cancel, your subscription will stop at the end of the current billing cycle.</li></ol>'
      },
      {
        question: 'How do I request a refund for a purchase made in the app?',
        answer: '<ul><li>For iOS users, you need to visit the following Apple webpage <a href="https://reportaproblem.apple.com" target="_blank">https://reportaproblem.apple.com</a> and follow the steps to request a refund.</li><li>For Android users, you need to send us a screenshot of your receipt that includes a unique order id called “GPA” to find the transaction. Please note that purchases requested beyond 14 days after purchase cannot be reimbursed.</li></ul>'
      },
      {
        question: 'How does the free trial work?',
        answer: 'You can test the Premium features for free for several days (see more information available in the app) when you subscribe to a plan.<br/><br/>Please note that the paid subscription will automatically start at the end of the free trial period if you don’t cancel it before its ending.'
      },
      {
        question: 'Where can I find the term of use for edjing Mix?',
        answer: 'You can find the term of use here: <a href="https://edjing.com/static_doc/toc/edjing.html" target="_blank">https://edjing.com/static_doc/toc/edjing.html</a>'
      },
      {
        question: 'Is my data safe while using the edjing Mix app?',
        answer: 'edjing Mix takes data privacy and security very seriously. The app implements measures to ensure that user data is protected, including secure connections and encryption of sensitive data. The app also does not share user data with third parties without explicit consent.'
      },
      {
        question: 'Is it safe to make a payment through edjing Mix app?',
        answer: 'It is important to note that all payments are made through either the Apple AppStore or Google Play Store. This means that the payment system used is the same one that is used for all other apps available on these platforms.<br/><br/>Both Apple and Google have strict security measures in place to ensure the safety of their payment systems. This includes using secure servers to process payments and encrypting all payment information to protect against fraud.'
      },
      {
        question: 'Who develops the edjing Mix app?',
        answer: 'MWM is a significant player in the mobile app development and publishing industry, becoming #1 French app publisher in terms of downloads. The company\'s portfolio boasts over 50 mobile apps, covering a wide range of categories including music, drawing, photography and video.<br/><br/>Among their successful apps are the renowned DJ app, edjing Mix, the virtual drum pad tool, Beat Maker Pro, and world\'s first AI-powered coloring app Color Pop, contributing to hundreds of millions of downloads worldwide. This success is attributed to MWM\'s dedicated in-house R&D department in Machine Learning and AI, constantly pushing the envelope in technological innovation and creativity.<br/><br/>MWM, headquartered in Boulogne-Billancourt, is recognized as one of the top 120 startups in France, having raised $75 million in funding to date from notable investors such as French DJ Martin Solveig, Blisce, Eurazeo, Aglaé Ventures, BPI and Xavier Niel.<br/><br/>However, the company\'s focus extends beyond numbers and accolades. MWM is driven by a mission to help users explore and realize their potential through the use of their innovative apps.'
      },
    ],
  }),
  methods: {

  },
  computed: {
    ...mapGetters([
      'isMobile',
    ])
  },
  mounted () {
    this.$nextTick(() => {

    })
  },
};
</script>

<style scoped lang="scss">
@media (max-width: 960px) {
  .mwm-faq-section-title {
    font-size: 32px!important;
  }
  .mwm-faq-title {
    font-size: 18px!important;
  }
  .mwm-faq-text {
    font-size: 14px!important;
  }
}

.mwm-faq-container {
  padding-top: 64px;
  color: white;
}
.mwm-faq-page-title {
  font-family: 'Inter', sans-serif;
  font-size: 58px;
  font-weight: 700;
  line-height: 70px;
  letter-spacing: -2px;
  text-align: center;
  margin-bottom: 6rem;
  margin-left: auto;
  margin-right: auto;
}
.mwm-faq-section-title {
  font-family: 'Inter', sans-serif;
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: -2px;
  text-align: left;
}
.mwm-faq-title {
  font-family: 'Inter', sans-serif;
  font-size: 22px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: -0.5px;
  text-align: left;
}
.mwm-faq-text {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0px;
  text-align: left;
}
.theme--dark.v-expansion-panels .v-expansion-panel {
  background-color: black;
}
.v-expansion-panels {
  justify-content: flex-start;
}
.theme--dark.v-expansion-panels .v-expansion-panel:not(:first-child):after {
  border-color: #D9D9D966;;
}
</style>
