<template>
  <v-app>
    <v-app-bar app elevation="0" :color="isMobile ? 'black' : ''" class="topbar--background">
      <v-row v-if="!isMobile" class="topbar-container" no-gutters align="center">
        <img class="topbar--icon" src="/static/01_top_icon.png" />
        <img class="topbar--logo" src="/static/01_top_logo.png" />
        <img @click="openDesktopStoreAndroid" class="topbar--store-google" src="/static/all_badge_playstore.png" >
        <img @click="openDesktopStoreIos" class="topbar--store-apple" src="/static/all_badge_appstore.png" >
      </v-row>
      <v-row v-if="isMobile" class="topbar-container" no-gutters align="center" justify="space-between">
        <v-col cols="auto">
          <img class="topbar--icon" src="/static/01_top_icon.png" >
        </v-col>
        <v-col cols="auto">
          <img class="topbar--logo" src="/static/01_top_logo.png" >
        </v-col>
        <v-spacer />
        <v-col v-if="!fromApp" cols="auto">
          <v-btn class="topbar--download-btn">
            <span @click="openStore" class="topbar--download-btn-text">Download</span>
          </v-btn>
        </v-col>
      </v-row>
    </v-app-bar>

    <v-main class="mwm-main">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';

import CssHolder from '@/mixins/CssHolder.vue';
import SizeHolder from '@/mixins/SizeHolder.vue';

const uuidv4 = require('uuid/v4')

export default {
  mixins: [CssHolder, SizeHolder],
  data: () => ({

  }),
  methods: {
    openStore () {
      const url = 'https://edjing-website-landing-page.onelink.me/Yx8k/100d32e5'
      const win = window.open(url, '_blank')
      win.focus()
    },
    openDesktopStoreIos () {
      const url = 'https://apps.apple.com/us/app/id493226494'
      const win = window.open(url, '_blank')
      win.focus()
    },
    openDesktopStoreAndroid () {
      const url = 'https://play.google.com/store/apps/details?id=com.edjing.edjingdjturntable'
      const win = window.open(url, '_blank')
      win.focus()
    }
  },
  computed: {
    ...mapGetters([
      'isMobile',
      'currentInstallationId'
    ]),
    fromApp () {
      return Object.prototype.hasOwnProperty.call(this.$route.query, 'from') && this.$route.query.from === 'app'
    }
  },
  mounted () {
    if (this.currentInstallationId === '') {
      this.$store.commit('setInstallationId', uuidv4());
    }
  }
};
</script>

<style lang="scss">
* {
  // font-family: 'Inter', sans-serif!important;
}

* p {
  margin-bottom: 0!important;
}

@media (max-width: 960px) {
  .topbar--icon {
    // position: unset!important;
    top: 50%!important;
    -ms-transform: translateY(-50%)!important;
    transform: translateY(-50%)!important;
    // width: unset!important;
    // height: unset!important;
    left: 1rem!important;
  }
  .topbar--logo {
      top: 50%!important;
      -ms-transform: translateY(-50%)!important;
      transform: translateY(-50%)!important;
    // position: unset!important;
    // width: unset!important;
    // height: unset!important;
    left: 3rem!important;
  }
}

.topbar--background {
  background: rgba(0, 0, 0, 0.2)!important;
}
.topbar-container {

}
.topbar--icon {
  position: absolute;
  width: 30px;
  height: 30px;
  left: 10rem;
}
.topbar--logo {
  position: absolute;
  width: 79px;
  height: 23px;
  left: 12.5rem;
}
.topbar--store-apple {
  position: absolute;
  width: 126px;
  height: 42px;
  cursor: pointer;
  right: 20rem;
}
.topbar--store-google {
  position: absolute;
  width: 142px;
  height: 42px;
  cursor: pointer;
  right: 10rem;
}
.topbar--download-btn {
  width: 7rem;
  height: 33px;
  background: linear-gradient(295.35deg, #0041E8 20.5%, #2562FF 78.87%), linear-gradient(271deg, #EC6D11 -4.57%, #FAC366 99.77%);
  box-shadow: 0px 1px 3px #0141E8;
  border-radius: 4px;
}
.topbar--download-btn-text {
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #FFFFFF;
}

.mwm-main {
  padding-top: 0px!important;
  background-color: black;
}
</style>
