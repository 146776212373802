<template>
  <v-row no-gutters class="community-content">
    <v-col cols="12">
      <div class="community-title-container">
        <v-row no-gutters justify="space-around">
          <v-col cols="10">
            <span class="community-title" v-html="communityTitle" />
          </v-col>
        </v-row>
      </div>
      <v-row no-gutters justify="space-around">
        <v-col :cols="isMobile ? 10 : 5">
          <v-row no-gutters justify="center">
            <v-col class="community-icon-container" cols="auto" align="center" v-for="community in communityCommunities" :key="community.key">
              <!-- <div class="community-icon-container">
                <img class="community-icon" :src="'static/android.png'" >
              </div>
              <div>
                <span class="community-icon-text">{{ community.title }}</span>
              </div> -->
              <img @click="openUrl(community.link)" class="community-icon" :src="community.icon" >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <!-- <v-row no-gutters justify="space-around">
        <v-col cols="5">
          <v-row no-gutters justify="space-around">
            <v-col cols="3" align="center" v-for="community in communityCommunities" :key="community.key">
              <div class="community-icon-container">
                <img class="community-icon" :src="'static/android.png'" >
              </div>
              <div>
                <span class="community-icon-text">{{ community.title }}</span>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row> -->
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {

  },
  data: () => ({
    communityTitle: 'Join the biggest<br />DJ community on mobile',
    communityCommunities: [
      { key: 0, title: 'Facebook', icon: '/static/06_community_facebook.png', link: 'https://www.facebook.com/edjingApp' },
      { key: 1, title: 'YouTube', icon: '/static/06_community_youtube.png', link: 'https://www.youtube.com/user/eDJingFrance/videos' },
      { key: 2, title: 'Instagram', icon: '/static/06_community_instagram.png', link: 'https://www.instagram.com/edjingofficial/' },
      { key: 3, title: 'TikTok', icon: '/static/06_community_tiktok.png', link: 'https://www.tiktok.com/@edjing.mix?lang=fr&is_copy_url=1&is_from_webapp=v1' },
    ]
  }),
  methods: {
    openUrl (link) {
      const win = window.open(link, '_blank')
      win.focus()
    }
  },
  computed: {
    ...mapGetters([
      'isMobile'
    ])
  },
  mounted () {

  }
};
</script>

<style scoped lang="scss">
@media (max-width: 960px) {
  .community-content {
    height: 45vh!important;
  }
  .community-title {
    font-size: 28px!important;
    line-height: 33px!important;
  }
  .community-icon-container {
    margin-top: 2rem!important;
  }
  .community-icon {
    width: 60px!important;
    height: 85px!important;
    margin-left: 0.5rem!important;
    margin-right: 0.5rem!important;
  }
}

.community-content {
  // height: 60vh;
  background-color: black;
  background-image: url('/static/06_community_background.png');
  background-size: cover;
  background-position: center;
  text-align: center;
  align-items: center;
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.community-title-container {
  // padding-top: 6rem;
}
.community-title {
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  font-size: 58px;
  line-height: 70px;
  text-align: center;
  letter-spacing: -2px;
  color: #FFFFFF;
}
.community-icon-container {
  margin-top: 4rem;
}
// .community-icon-container {
//   margin-top: 4rem;
//   position: relative;
//   width: 80px;
//   height: 80px;
//   background: rgba(255, 255, 255, 0.15);
//   border-radius: 10px;
// }
.community-icon {
  width: 80px;
  height: 113px;
  margin-left: 1rem;
  margin-right: 1rem;
  cursor: pointer;
}
// .community-icon {
//   position: absolute;
//   left: 25%;
//   top: 25%;
// }
.community-icon-text {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
}
</style>
