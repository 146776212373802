<template>
  <v-row v-if="isMobile" no-gutters class="facts-content">
    <v-col cols="12">
      <v-row no-gutters class="pb-5" justify="space-around">
        <v-col cols="10">
          <span class="facts-title" v-html="factsTitle" />
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-5" justify="space-around">
        <v-col cols="10">
          <v-row class="mb-5" no-gutters v-for="block in factsBlocks" :key="block.key">
            <span class="facts-text" v-html="block.text" />
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
  <v-row v-else-if="!isMobile" no-gutters class="facts-content">
    <v-col cols="12">
      <v-row no-gutters class="pb-5" justify="space-around">
        <v-col cols="10">
          <span class="facts-title" v-html="factsTitle" />
        </v-col>
      </v-row>
      <v-row no-gutters class="pt-5" justify="space-around">
        <v-col cols="10">
          <v-row no-gutters>
            <v-col cols="3" v-for="block in factsBlocks" :key="block.key">
              <span class="facts-text" v-html="block.text" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {

  },
  data: () => ({
    factsTitle: 'Turn your phone<br />into the best DJ set up',
    factsBlocks: [
      { key: 0, text: 'Leader on mobile Djing<br /> since 9 years' },
      { key: 1, text: 'Professional DJ features<br /> accessibles for all' },
      { key: 2, text: 'Millions tracks ready<br /> to mix & remix' },
      { key: 3, text: 'The biggest DJ<br /> community on mobile' },
    ]
  }),
  methods: {

  },
  computed: {
    ...mapGetters([
      'isMobile'
    ])
  },
  mounted () {

  }
};
</script>

<style scoped lang="scss">
@media (max-width: 960px) {
  .facts-content {
    height: 85vh!important;
    padding-top: 4rem!important;
    padding-bottom: 3rem!important;
  }
  .facts-title {
    font-size: 28px!important;
    line-height: 33px!important;
  }
  .facts-text {
    font-size: 20px!important;
    line-height: 24px!important;
  }
}

.facts-content {
  // height: 40vh;
  background-color: black;
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.facts-title {
  font-family: 'Inter', sans-serif;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 58px;
  line-height: 70px;
  letter-spacing: -2px;
}
.facts-text {
  font-family: 'Inter', sans-serif;
  color: #FFFFFF;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  letter-spacing: -0.5px;
}
</style>
