import Vue from 'vue';
import VueRouter from 'vue-router';

import Landing from '@/components/Landing.vue';
import Mixes from '@/components/Mixes.vue';
import Faq from '@/components/Faq.vue';
// import Tidal from '@/components/Tidal.vue';

Vue.use(VueRouter);

const routes = [
  { path: '*', redirect: '/' },
  { path: '/', name: 'landing', component: Landing },
  { path: '/mixes/:mix_id', name: 'mixes', component: Mixes },
  { path: '/faq', name: 'faq', component: Faq },
  // { path: '/tidal', name: 'tidal', component: Tidal },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
