<template>
  <v-row no-gutters class="features-content">
    <v-col cols="1"></v-col>
    <v-col :cols="isMobile ? 10 : 5">
      <v-row no-gutters>
        <span class="features-title" v-html="featuresTitle" />
      </v-row>
      <div class="features-features">
        <v-row class="features-feature" v-for="feature in features" :key="feature.key" no-gutters>
          <v-col v-if="!isMobile" cols="1">
            <img class="features-feature-icon" :src="feature.icon" >
          </v-col>
          <v-col cols="11">
            <span class="features-feature-title">{{ feature.title }}</span><br/>
            <span class="features-feature-text">{{ feature.text }}</span>
          </v-col>
        </v-row>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {

  },
  data: () => ({
    featuresTitle: 'Pro DJ setup<br />made accessible<br />for beginners',
    features: [
      { key: 0, icon: '/static/04_features_prodjfx.png', title: 'Pro DJ FX', text: 'Use our many available effects to spice up your mix' },
      { key: 1, icon: '/static/04_features_autosync.png', title: 'Auto-Sync', text: ' Beatmatch all your favorite tracks to sound like a pro' },
      { key: 2, icon: '/static/04_features_sampler.png', title: 'Sampler', text: 'Remix your music in real-time thanks to our exclusive sound banks' },
      { key: 3, icon: '/static/04_features_record.png', title: 'High quality record', text: 'Record and save all your DJ sets' },
      { key: 4, icon: '/static/04_features_sharing.png', title: 'Easy mix sharing', text: 'Share your mixes on all social media platforms' },
      { key: 5, icon: '/static/04_features_automix.png', title: 'Automix', text: 'Let edjing automatically mix your songs together' },
    ]
  }),
  methods: {

  },
  computed: {
    ...mapGetters([
      'isMobile'
    ])
  },
  mounted () {

  }
};
</script>

<style scoped lang="scss">
@media (max-width: 960px) {
  .features-content {
    height: 140vh!important;
  }
  .features-title {
    font-size: 28px!important;
    line-height: 33px!important;
  }
  .features-feature-title {
    font-size: 20px!important;
    line-height: 24px!important;
  }
  .features-feature-text {
    color: #FFFFFF!important;
  }
}

.features-content {
  // height: 120vh;
  background-color: black;
  background-image: url('/static/04_features_background.png');
  background-size: cover;
  background-position: center;
  padding-bottom: 4rem;
}
.features-title {
  font-family: 'Inter', sans-serif;
  padding-top: 7rem;
  font-weight: bold;
  font-size: 58px;
  line-height: 70px;
  letter-spacing: -2px;
  color: #FFFFFF;
}
.features-features {
  padding-top: 4rem;
}
.features-feature {
  padding-bottom: 2rem;
}
.features-feature-icon {
  width: 24px;
  height: 24px;
}
.features-feature-title {
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  letter-spacing: -0.5px;
  color: #FFFFFF;
}
.features-feature-text {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  line-height: 17px;
  color: rgba(255, 255, 255, 0.6);
}
</style>
