<template>
  <v-row no-gutters class="bottom-content" justify="space-around">
    <v-col :cols="isMobile ? 10 : 5">
      <v-row class="bottom-icons-container" no-gutters justify="center" align="center">
        <v-col cols="auto">
          <img class="bottom--icon" src="static/01_top_icon.png" />
        </v-col>
        <v-col cols="auto">
          <img class="bottom--logo" src="static/01_top_logo.png" />
        </v-col>
      </v-row>
      <v-row no-gutters justify="center">
        <span class="bottom--title" v-html="bottomText" />
      </v-row>
      <v-row v-if="isMobile" class="bottom-stores-container" no-gutters justify="center">
        <v-col cols="6">
          <img @click="openStore" class="bottom--store-apple" src="static/all_badge_appstore.png" >
        </v-col>
        <v-col cols="6">
          <img @click="openStore" class="bottom--store-google" src="static/all_badge_playstore.png" >
        </v-col>
      </v-row>
      <v-row v-else-if="!isMobile" class="bottom-stores-container" no-gutters justify="center">
        <v-col cols="auto">
          <div class="bottom--store-apple-container">
            <a href="https://apps.apple.com/us/app/id493226494" target="_blank">
              <img class="bottom--store-apple" src="static/all_badge_appstore.png" >
            </a>
          </div>
        </v-col>
        <v-col cols="auto">
          <a href="https://play.google.com/store/apps/details?id=com.edjing.edjingdjturntable" target="_blank">
            <img class="bottom--store-google" src="static/all_badge_playstore.png" >
          </a>
        </v-col>
      </v-row>
      <v-row class="bottom-account-button-container" no-gutters justify="center">
        <v-col cols="auto">
          <!-- <v-btn color="#ffffff1a" @click="openUrl('https://join.edjing.com/account')">
            <span class="bottom-account-button--title" v-html="accountBtnText" />
          </v-btn> -->
          <a class="bottom-account-link" href="https://join.edjing.com/account" target="_blank">
            <div class="bottom-account-button">
              <span class="bottom-account-button--title"> {{ accountBtnText }} </span>
            </div>
          </a>
        </v-col>
      </v-row>
      <v-row class="bottom-links-container" no-gutters justify="space-around">
        <v-col :cols="isMobile ? 10 : 8">
          <v-row no-gutters justify="space-around">
            <v-col cols="3" align="center" v-for="link in bottomLinks" :key="link.key">
              <span @click="openUrl(link.link)" class="bottom-link-text">{{ link.title }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {

  },
  data: () => ({
    bottomText: 'The #1 App to mix on your iPhone, iPad,<br /> Android phones & tablets',
    bottomLinks: [
      { key: 0, title: 'Terms of use', link: 'https://edjing.com/public/files/tos/edjing.html' },
      // { key: 1, title: 'FAQ', link: '' },
      { key: 2, title: 'Press', link: 'https://www.musicworldmedia.com/press' },
      { key: 3, title: 'Jobs', link: 'https://www.musicworldmedia.com/career' },
    ],

    accountBtnText: 'Already have an account?'
  }),
  methods: {
    openUrl (link) {
      const win = window.open(link, '_blank')
      win.focus()
    },
    openStore () {
      const url = 'https://edjing-website-landing-page.onelink.me/Yx8k/153bc17d'
      const win = window.open(url, '_blank')
      win.focus()
    }
  },
  computed: {
    ...mapGetters([
      'isMobile'
    ])
  },
  mounted () {

  }
};
</script>

<style scoped lang="scss">
@media (max-width: 960px) {
  .bottom-content {
    height: 50vh!important;
  }
  .bottom--icon {
    width: 60px!important;
    height: 60px!important;
  }
  .bottom--logo {
    width: 128px!important;
    height: 36px!important;
  }
  .bottom--title {
    font-size: 20px!important;
    line-height: 22px!important;
  }
  .bottom--store-apple {
    width: 128px!important;
    height: 43px!important;
    margin-left: unset!important;
  }
  .bottom--store-google {
    width: 148px!important;
    height: 43px!important;
    margin-left: unset!important;
  }
  .bottom-link-text {
    font-size: 10px!important;
  }
}

.bottom-content {
  // height: 60vh;
  background-color: black;
  text-align: center;
  align-items: center;
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.bottom-icons-container {
  // margin-top: 5rem!important;
}
.bottom--icon {
  width: 90px;
  height: 90px;
}
.bottom--title {
  font-family: 'Inter', sans-serif;
  margin-top: 1rem;
  text-align: center;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  letter-spacing: -1.2px;
  color: rgba(255, 255, 255, 0.8);
}
.bottom--logo {
  width: 170px;
  height: 48px;
}
.bottom-stores-container {
  margin-top: 2rem!important;
}
.bottom--store-apple-container {
  height: 64px;
  width: 216px;
  margin-right: 1rem;
}
.bottom--store-apple {
  height: 64px;
  width: 191px;
  margin-left: 25px;
  cursor: pointer;
}
.bottom--store-google {
  height: 64px;
  width: 216px;
  margin-left: 1rem;
  cursor: pointer;
}
.bottom-account-button-container {
  margin-top: 2rem!important;
}
.bottom-account-link {
  text-decoration: none;
}
.bottom-account-button {
  border: 0.5px solid rgba(255, 255, 255, 0.3);
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.1);

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 1px 24px;
}
.bottom-account-button--title {
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 19px;
  color: white;
  text-transform: none;
  padding: 1rem;
}
.bottom-links-container {
  margin-top: 2rem!important;
}
.bottom-link-text {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: rgba(255, 255, 255, 0.6);
  cursor: pointer;
}
</style>
