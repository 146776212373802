<template>
  <v-row no-gutters class="integrations-content">
    <v-col cols="12">
      <v-row no-gutters justify="space-around" class="integrations-title-container">
        <v-col cols="10">
          <span class="integrations-title" v-html="integrationsTitle" />
        </v-col>
      </v-row>
      <v-row no-gutters class="integrations-subtitle-container">
        <v-col cols="1"> </v-col>
        <v-col :cols="isMobile ? 10 : 6">
          <span class="integrations-subtitle" v-html="integrationsSubTitle" />
        </v-col>
      </v-row>
      <v-row v-if="isMobile" no-gutters class="integrations-cards-container" justify="space-around">
        <v-col cols="10">
          <v-row no-gutters>
            <v-col cols="6" v-for="(integration, index) in mobileIntegrations" :key="integration.key">
              <v-img eager @click="showIntegrationsDialog(integration.target, integration.logo, integration.title, integration.with_connect)" :class="index %2 === 0 ? 'integrations-mob-left' : 'integrations-mob-right'" :src="integration.icon" />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row v-else-if="!isMobile" no-gutters class="integrations-cards-container" justify="space-around">
        <v-col cols="10">
          <v-row no-gutters>
            <v-col cols="4" v-for="integration in integrations" :key="integration.key">
              <v-row no-gutters class="integrations-card integrations-zoom mr-5 my-2">
                <v-container class="mx-4 my-4" @click="showIntegrationsDialog(integration.target, integration.icon, integration.title, integration.with_connect)">
                  <v-row no-gutters class="pb-5" justify="space-between">
                    <v-col cols="2">
                      <img class="integrations-icon" :src="integration.icon" >
                    </v-col>
                    <v-col cols="auto">
                      <img v-if="integration.platforms.includes('android')" class="mr-2 integrations-icon-android" :src="'static/android.png'" >
                      <img v-if="integration.platforms.includes('ios')" class="ml-2 integrations-icon-ios" :src="'static/ios.png'" >
                    </v-col>
                  </v-row>
                  <v-row no-gutters justify="space-around">
                    <v-col cols="12">
                      <span class="integrations-card-title" v-html="integration.title" />
                      <v-chip class="ml-4" small color="white" outlined v-if="integration.soon">
                        <span class="font-weight-medium">{{ integrationCommingSoon }}</span>
                      </v-chip>
                    </v-col>
                  </v-row>
                  <v-row no-gutters justify="space-around">
                    <v-col cols="12">
                      <span class="integrations-card-text" v-html="integration.text" />
                    </v-col>
                  </v-row>
                  <v-row no-gutters justify="space-around">
                    <v-col cols="12">
                      <span class="integrations-card-howto" v-html="integrationsHowTo" />
                      <v-icon class="integrations-card-howto-icon" color="white" x-small>arrow_forward_ios</v-icon>
                    </v-col>
                  </v-row>
                </v-container>
              </v-row>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-dialog v-model="integrationsShowDialog" width="80%">
        <v-card v-if="!isMobile" class="integrations-dialog">
          <div class="integrations-dialog-title-container">
            <v-row no-gutters align="center">
              <v-col cols="auto"> <img class="integrations-icon" :src="integrationsDialogIcon" > </v-col>
              <v-col cols="auto"> <span class="integrations-dialog-title">How to link your {{ integrationsDialogTitle }} account in edjing Mix</span> </v-col>
            </v-row>
          </div>
          <div class="integrations-dialog-text-container">
            <span class="integrations-dialog-text" v-html="integrationsDialogText" />
          </div>
        </v-card>
        <v-card v-else-if="isMobile" class="integrations-dialog">
          <div class="integrations-dialog-title-container">
            <v-row no-gutters align="center">
              <v-col cols="auto"> <img class="integrations-icon" :src="integrationsDialogIcon" > </v-col>
            </v-row>
            <v-row no-gutters align="center">
              <v-col cols="auto"> <span class="integrations-dialog-title">How to link your {{ integrationsDialogTitle }} account in edjing Mix</span> </v-col>
            </v-row>
          </div>
          <div class="integrations-dialog-text-container">
            <span class="integrations-dialog-text" v-html="integrationsDialogText" />
          </div>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {

  },
  data: () => ({
    integrationsTitle: 'Mix the music<br /> you need',
    integrationsSubTitle: 'Enjoy an extensive music library that offers the most creativity freedom and switch between your own mp3, streaming sources, or tracks from your cloud storage platforms.',
    integrationsHowTo: 'How to link in edjing',
    integrationCommingSoon: 'Coming soon',
    integrations: [
      { key: 4, target: 'edjing', with_connect: false, icon: '/static/03_integrations_edjing.png', soon: false, platforms: ['android', 'ios'], title: 'edjing tracks', text: 'Fine music selection hand-picked by our product specialists', link: '' },
      { key: 5, target: 'device', with_connect: false, icon: '/static/03_integrations_device.png', soon: false, platforms: ['android', 'ios'], title: 'On Device / Music', text: 'Browse your best tracks directly on your phone', link: '' },
      { key: 2, target: 'beatport', with_connect: true, icon: '/static/03_integrations_beatport.png', soon: false, platforms: ['ios'], title: 'Beatport', text: 'The best of electronic music with playlists curated by some of the best DJs in the industry.', link: '' },
      { key: 3, target: 'beatsource', with_connect: true, icon: '/static/03_integrations_beatsource.png', soon: false, platforms: ['ios'], title: 'Beatsource', text: 'Extensive music catalog for open formats DJs.', link: '' },
      // { key: 6, target: 'applemusic', with_connect: false, icon: '/static/03_integrations_applemusic.png', soon: false, platforms: ['ios'], title: 'Music', text: 'Fine music selection hand-picked by our product specialists', link: '' },
      { key: 1, target: 'soundcloud', with_connect: true, icon: '/static/03_integrations_soundcloud.png', soon: false, platforms: ['android', 'ios'], title: 'Soundcloud Go+', text: 'Access to your Soundcloud catalogue and mix it in one click', link: '' },
      { key: 0, target: 'tidal', with_connect: true, icon: '/static/03_integrations_tidal.png', soon: false, platforms: ['android', 'ios'], title: 'TIDAL', text: 'Enjoy the unique audio system and the extensive collection of music', link: '' },
      { key: 7, target: 'dropbox', with_connect: true, icon: '/static/03_integrations_dropbox.png', soon: false, platforms: ['android', 'ios'], title: 'Dropbox', text: 'Download your files and music from your personal storage', link: '' },
      { key: 9, target: 'icloud', with_connect: false, icon: '/static/03_integrations_icloud.png', soon: false, platforms: ['ios'], title: 'iCloud', text: 'Access your playlists directly from the cloud', link: '' },
      { key: 8, target: 'drive', with_connect: true, icon: '/static/03_integrations_drive.png', soon: false, platforms: ['android', 'ios'], title: 'Drive', text: 'Easily transfer tracks from your stored music', link: '' },
    ],
    mobileIntegrations: [
      { key: 4, target: 'edjing', with_connect: false, icon: '/static/03_integrations_mob_edjing.png', logo: '/static/03_integrations_edjing.png', title: 'edjing tracks' },
      { key: 5, target: 'device', with_connect: false, icon: '/static/03_integrations_mob_device.png', logo: '/static/03_integrations_device.png', title: 'On Device / Music' },
      { key: 3, target: 'beatsource', with_connect: true, icon: '/static/03_integrations_mob_beatsource.png', logo: '/static/03_integrations_beatsource.png', title: 'Beatsource' },
      { key: 2, target: 'beatport', with_connect: true, icon: '/static/03_integrations_mob_beatport.png', logo: '/static/03_integrations_beatport.png', title: 'Beatport' },
      // { key: 6, target: 'applemusic', with_connect: false, icon: '/static/03_integrations_mob_applemusic.png', logo: '/static/03_integrations_applemusic.png', title: 'Music' },
      { key: 0, target: 'tidal', with_connect: true, icon: '/static/03_integrations_mob_tidal.png', logo: '/static/03_integrations_tidal.png', title: 'TIDAL' },
      { key: 1, target: 'soundcloud', with_connect: true, icon: '/static/03_integrations_mob_soundcloud.png', logo: '/static/03_integrations_soundcloud.png', title: 'Soundcloud Go+' },
      { key: 7, target: 'dropbox', with_connect: true, icon: '/static/03_integrations_mob_dropbox.png', logo: '/static/03_integrations_dropbox.png', title: 'Dropbox' },
      { key: 8, target: 'drive', with_connect: true, icon: '/static/03_integrations_mob_drive.png', logo: '/static/03_integrations_drive.png', title: 'Drive' },
      { key: 9, target: 'icloud', with_connect: false, icon: '/static/03_integrations_mob_icloud.png', logo: '/static/03_integrations_icloud.png', title: 'iCloud' },
    ],

    integrationsShowDialog: false,
    integrationsDialogTarget: '',
    integrationsDialogIcon: '',
    integrationsDialogTitle: '',
    integrationsDialogText: '',
    // integrationsInstructions: '1. <b>Téléchargez edjing Mix</b> sur l’AppStore ou le Google Play Store<br/>2. Ouvrez la library musicale<br/>3. Cliquez en haut à gauche sur le sélecteur de source de musique<br/>4. Sélectionnez <b>Tidal</b><br/>5. Cliquez sur <b>S’inscrire / Se connecter</b> à Tidal<br/>6. Une fois connecté, <b>enjoy your life ;)</b><br/>'
  }),
  methods: {
    showIntegrationsDialog (target, icon, title, withConnect) {
      this.integrationsDialogTarget = target
      this.integrationsDialogIcon = icon
      this.integrationsDialogTitle = title
      this.integrationsDialogText = withConnect ? this.integrationsInstructionsWithConnection : this.integrationsInstructionsWithoutConnection
      this.integrationsShowDialog = true
    }
  },
  computed: {
    ...mapGetters([
      'isMobile'
    ]),
    integrationsInstructionsWithConnection () {
      return `
        1. <b>Download edjing Mix</b> from the AppStore or Google Play Store<br/>
        2. Open the music library<br/>
        3. Click on the music source selector in the top left corner<br/>
        4. Select <b>${this.integrationsDialogTitle}</b><br/>
        5. Click on <b>Sign Up / Connect</b> to ${this.integrationsDialogTitle}<br/>
        6. Once connected, browse in your library the track you want to use<br/>
        7. <b>Enjoy, mix, share</b> 🙂
      `
    },
    integrationsInstructionsWithoutConnection () {
      return `
        1. <b>Download edjing Mix</b> from the AppStore or Google Play Store<br/>
        2. Open the music library<br/>
        3. Click on the music source selector in the top left corner<br/>
        4. Select <b>${this.integrationsDialogTitle}</b><br/>
        5. Browse directly in your library the track you want to use<br/>
        6. <b>Enjoy, mix, share</b> 🙂
      `
    }
  },
  mounted () {

  }
};
</script>

<style scoped lang="scss">
@media (max-width: 960px) {
  .integrations-content {
    padding-top: 4rem!important;
    padding-bottom: 1rem!important;
  }
  .integrations-title {
    font-size: 28px!important;
    line-height: 33px!important;
  }
  .integrations-subtitle-container {
    padding-top: 1rem!important;
  }
  .integrations-subtitle {
    font-size: 22px!important;
    line-height: 29px!important;
  }
  .integrations-cards-container {
    padding-top: 1rem!important;
    padding-bottom: 3rem;
  }
  .integrations-mob-left {
    margin-top: 1rem;
    margin-right: 0.5rem!important;
  }
  .integrations-mob-right {
    margin-top: 1rem;
    margin-left: 0.5rem!important;
  }

  .integrations-dialog {
    padding-left: 1rem!important;
    padding-right: 1rem!important;
  }
  .integrations-dialog-title {
    font-size: 16px!important;
    margin-left: unset!important;
  }
  .integrations-dialog-title-container {
    padding-top: 2rem!important;
  }
  .integrations-dialog-text-container {
    padding-top: 2rem!important;
    padding-bottom: 2rem!important;
  }
  .integrations-dialog-text {
    font-size: 11px!important;
  }
}

.integrations-content {
  background-color: #3E2486;
  padding-top: 5rem;
  padding-bottom: 5rem;
}
.integrations-title-container {

}
.integrations-title {
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  font-size: 58px;
  line-height: 70px;
  letter-spacing: -2px;
  color: #FFFFFF;
}
.integrations-subtitle-container {
  padding-top: 3rem;
}
.integrations-subtitle {
  font-family: 'Inter', sans-serif;
  font-size: 34px;
  line-height: 41px;
  letter-spacing: -1.5px;
  color: #FFFFFF;
}
.integrations-cards-container {
  padding-top: 3rem;
}
.integrations-card {
  background: rgba(255, 255, 255, 0.15);
  border-radius: 10px;
  min-height: 231px;
}
.integrations-card-title {
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  letter-spacing: -0.5px;
  color: #FFFFFF;
}
.integrations-card-text {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  color: #FFFFFF;
}
.integrations-card-howto {
  font-family: 'Inter', sans-serif;
  opacity: 0.6;
  font-size: 12px;
  line-height: 15px;
  color: #FFFFFF;
}
.integrations-card-howto-icon {
  opacity: 0.6;
}
.integrations-icon {
  border-radius: 10px;
  width: 48px;
  height: 48px;
}
.integrations-icon-android {
  opacity: 50%;
  width: 29px;
  height: 16px;
}
.integrations-icon-ios {
  opacity: 50%;
  width: 25px;
  height: 12px;
}
.integrations-dialog {
  background: linear-gradient(136.29deg, #932A77 7.03%, #3E2486 77.32%)!important;
  border-radius: 10px;
  padding-left: 3rem;
  padding-right: 3rem;
}
.integrations-dialog-title-container {
  padding-top: 3rem;
}
.integrations-dialog-title {
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  font-size: 22px;
  line-height: 27px;
  letter-spacing: -0.5px;
  color: #FFFFFF;
  margin-left: 1rem;
}
.integrations-dialog-text-container {
  padding-top: 2rem;
  padding-bottom: 3rem;
}
.integrations-dialog-text {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  line-height: 180%;
  color: #FFFFFF;
}

.integrations-zoom {
  transition: transform .2s;
  cursor: pointer;
}

.integrations-zoom:hover {
  transform: scale(1.03);
}
</style>
