<script>
import { mapGetters } from 'vuex';

export default {

  methods: {
    onResize() {
      const isMobile = window.screen.width < 960;
      this.$store.commit('setIsMobile', isMobile);
    },
  },

  computed: {
    ...mapGetters([
      'isMobile',
    ]),
  },

  mounted() {
    this.onResize();
    window.addEventListener('resize', this.onResize, { passive: true });
  },

  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.onResize, { passive: true });
    }
  },
};
</script>
