import Vue from 'vue';
import Vuex from 'vuex';

import mixesService from '@/api/mixes';
import pagesService from '@/api/pages';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isMobile: false,
    currentMix: null,
    currentMixStatus: null,
    currentMixError: null,
    currentTidalCode: null,
    currentInstallationId: '',
  },
  getters: {
    isMobile: state => state.isMobile,
    currentMix: state => state.currentMix,
    currentMixStatus: state => state.currentMixStatus,
    currentMixError: state => state.currentMixError,
    currentTidalCode: state => state.currentTidalCode,
    currentInstallationId: state => state.currentInstallationId,
  },
  mutations: {
    setIsMobile(state, isMobile) {
      state.isMobile = isMobile;
    },
    setCurrentMix(state, mix) {
      state.currentMix = mix;
    },
    setGetMixStatus(state, mix) {
      state.currentMixStatus = mix;
    },
    setGetMixError(state, mix) {
      state.currentMixError = mix;
    },
    setTidalCode(state, code) {
      state.currentTidalCode = code;
    },
    setInstallationId(state, installationId) {
      state.currentInstallationId = installationId;
    },
  },
  actions: {
    mixesGetMix: (context, payload) => {
      context.commit('setGetMixStatus', 'in_progress');
      context.commit('setGetMixError', null);
      return mixesService.getMix(payload)
        .then((response) => {
          context.commit('setCurrentMix', response.data);
          context.commit('setGetMixStatus', 'idle');
          return Promise.resolve(response);
        })
        .catch((error) => {
          context.commit('setGetMixStatus', 'error');
          context.commit('setGetMixError', error);
          return Promise.reject(error);
        });
    },
    sendEvent: ({ state }, payload) => {
      const evPayload = {
        installation_id: state.currentInstallationId,
        is_mobile: state.isMobile,
        tidal_code: state.currentTidalCode,
        event_type: payload.event_type,
        value: payload.value,
      };

      pagesService.sendEvent(evPayload)
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error));
    },
    askForTidalCode: ({ commit }) => {
      pagesService.getTidalCode()
        .then(response => commit('setTidalCode', response.data.code))
        .catch(error => Promise.reject(error));
    },
    burnTidalCode: (context, payload) => {
      pagesService.burnTidalCode(payload.code)
        .then(response => Promise.resolve(response))
        .catch(error => Promise.reject(error));
    },
  },
  modules: {

  },
});
