<template>
  <v-row no-gutters class="skins-content">
    <v-col cols="12">
      <div class="skins-title-container">
        <v-row no-gutters justify="space-around">
          <v-col cols="10">
            <span class="skins-title" v-html="skinsTitle" />
          </v-col>
        </v-row>
      </div>
      <v-row no-gutters justify="space-around">
        <v-col cols="12">
          <img class="skins-image" :src="'static/05_skins_mockups.png'" >
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {

  },
  data: () => ({
    skinsTitle: 'Make edjing yours<br />Choose your style'
  }),
  methods: {

  },
  computed: {
    ...mapGetters([
      'isMobile'
    ])
  },
  mounted () {

  }
};
</script>

<style scoped lang="scss">
@media (max-width: 960px) {
  .skins-content {
    height: unset!important;
  }
  .skins-title {
    font-size: 28px!important;
    line-height: 33px!important;
  }
  .skins-image {
    width: 100%!important;
    height: auto!important;
    padding-bottom: 5rem;
  }
}

.skins-content {
  // height: 75vh;
  background-color: black;
  background-image: url('/static/05_skins_background.png');
  background-size: cover;
  background-position: center;
  text-align: center;
  padding-top: 4rem;
  padding-bottom: 4rem;
}
.skins-title-container {
  padding-top: 6rem;
}
.skins-title {
  font-family: 'Inter', sans-serif;
  font-weight: bold;
  font-size: 58px;
  line-height: 70px;
  text-align: center;
  letter-spacing: -2px;
  color: #FFFFFF;
}
.skins-image {
  margin-top: 3rem;
  width: 1000px;
  height: 360px;
}
</style>
