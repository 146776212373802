<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-row no-gutters>
        <block-top />
      </v-row>
      <v-row no-gutters>
        <block-facts />
      </v-row>
      <v-row no-gutters>
        <block-integrations />
      </v-row>
      <v-row no-gutters>
        <block-features />
      </v-row>
      <v-row no-gutters>
        <block-skins />
      </v-row>
      <v-row no-gutters>
        <block-community />
      </v-row>
      <v-row no-gutters>
        <block-bottom />
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

import BlockTop from '@/components/blocks/01-Top.vue'
import BlockFacts from '@/components/blocks/02-Facts.vue'
import BlockIntegrations from '@/components/blocks/03-Integrations.vue'
import BlockFeatures from '@/components/blocks/04-Features.vue'
import BlockSkins from '@/components/blocks/05-Skins.vue'
import BlockCommunity from '@/components/blocks/06-Community.vue'
import BlockBottom from '@/components/blocks/07-Bottom.vue'

export default {
  components: {
    'block-top': BlockTop,
    'block-facts': BlockFacts,
    'block-integrations': BlockIntegrations,
    'block-features': BlockFeatures,
    'block-skins': BlockSkins,
    'block-community': BlockCommunity,
    'block-bottom': BlockBottom
  },
  data: () => ({

  }),
  methods: {

  },
  computed: {
    ...mapGetters([
      ''
    ])
  },
  mounted () {

  }
};
</script>

<style scoped lang="scss">

</style>
