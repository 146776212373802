/* eslint-disable no-unused-vars */
/* eslint-disable spaced-comment */

import axios from 'axios';
import sha1 from 'js-sha1';
import moment from 'moment';

//////////////////////////////////////////////////////////////////////
// API ENDPOINTS
//////////////////////////////////////////////////////////////////////

// ADMIN
const MWM_BI__PAGES_ROOT_PROD = 'https://store-backend-dot-mwm-bi.ew.r.appspot.com';
// const MWM_BI__PAGES_ROOT_DEV = 'https://dev-dot-store-backend-dot-mwm-bi.ew.r.appspot.com'';
const MWM_BI__PAGES_ROOT_DEV = 'http://localhost:8080';

// ADMIN
const EDJING_BACKEND__MIXES_ROOT_PROD = 'https://mixes-dot-edjing-backend.ew.r.appspot.com';
const EDJING_BACKEND__MIXES_ROOT_DEV = 'http://localhost:8080';

// ADMIN
const MWM_BI__TIDAL_ANALYTICS_PROD = 'https://api-dot-mwm-bi.ew.r.appspot.com';
const MWM_BI__TIDAL_ANALYTICS_DEV = 'http://localhost:8080';

// //////////////////////////////////////////////////////////////////////
// // MWM HEADERS
// //////////////////////////////////////////////////////////////////////
// const APP_VERSION = '0.01.00';

const HEADER_MWM_AUTH = 'MWM-AUTH';
// const HEADER_MWM_USER = 'MWM-USER';
// const HEADER_MWM_APP_VERSION = 'MWM-APP-VERSION';

//////////////////////////////////////////////////////////////////////
// AXIOS INSTANCES
//////////////////////////////////////////////////////////////////////
/* eslint-disable no-param-reassign */
const mwmBiPageInstance = axios.create({
  baseURL: MWM_BI__PAGES_ROOT_PROD,
  // transformRequest: [(data, headers) => {
  //   const currentHour = moment().utc().format().substring(0, 13);
  //   const authHeader = sha1(`${currentHour}MWM_Secure`);
  //   // const [userHeader] = firebase.auth().currentUser.email.split('@');
  //
  //   headers[HEADER_MWM_AUTH] = authHeader;
  //   // headers[HEADER_MWM_USER] = userHeader;
  //   // headers[HEADER_MWM_APP_VERSION] = APP_VERSION;
  //
  //   return data;
  // }, ...axios.defaults.transformRequest],
});

const mwmDevBiPageInstance = axios.create({
  baseURL: MWM_BI__PAGES_ROOT_DEV,
  // transformRequest: [(data, headers) => {
  //   const currentHour = moment().utc().format().substring(0, 13);
  //   const authHeader = sha1(`${currentHour}MWM_Secure`);
  //   // const [userHeader] = firebase.auth().currentUser.email.split('@');
  //
  //   headers[HEADER_MWM_AUTH] = authHeader;
  //   // headers[HEADER_MWM_USER] = userHeader;
  //   // headers[HEADER_MWM_APP_VERSION] = APP_VERSION;
  //
  //   return data;
  // }, ...axios.defaults.transformRequest],
});

//////////////////////////////////////////////////////////////////////
// AXIOS INSTANCES
//////////////////////////////////////////////////////////////////////
/* eslint-disable no-param-reassign */
const mwmEdjingBackendMixesInstance = axios.create({
  baseURL: EDJING_BACKEND__MIXES_ROOT_PROD,
  transformRequest: [(data, headers) => {
    const currentHour = moment().utc().format().substring(0, 13);
    const authHeader = sha1(`${currentHour}MWM_Secure`);
    // const [userHeader] = firebase.auth().currentUser.email.split('@');

    headers[HEADER_MWM_AUTH] = authHeader;
    // headers[HEADER_MWM_USER] = userHeader;
    // headers[HEADER_MWM_APP_VERSION] = APP_VERSION;

    return data;
  }, ...axios.defaults.transformRequest],
});

const mwmDevEdjingBackendMixesInstance = axios.create({
  baseURL: EDJING_BACKEND__MIXES_ROOT_DEV,
  transformRequest: [(data, headers) => {
    const currentHour = moment().utc().format().substring(0, 13);
    const authHeader = sha1(`${currentHour}MWM_Secure`);
    // const [userHeader] = firebase.auth().currentUser.email.split('@');

    headers[HEADER_MWM_AUTH] = authHeader;
    // headers[HEADER_MWM_USER] = userHeader;
    // headers[HEADER_MWM_APP_VERSION] = APP_VERSION;

    return data;
  }, ...axios.defaults.transformRequest],
});

const mwmEdjingTidalAnalyticsInstance = axios.create({
  baseURL: MWM_BI__TIDAL_ANALYTICS_PROD,
  transformRequest: [(data, headers) => {
    const currentHour = moment().utc().format().substring(0, 13);
    const authHeader = sha1(`${currentHour}MWM_Secure`);
    // const [userHeader] = firebase.auth().currentUser.email.split('@');

    headers[HEADER_MWM_AUTH] = authHeader;
    // headers[HEADER_MWM_USER] = userHeader;
    // headers[HEADER_MWM_APP_VERSION] = APP_VERSION;

    return data;
  }, ...axios.defaults.transformRequest],
});

export {
  mwmBiPageInstance,
  mwmDevBiPageInstance,
  mwmEdjingBackendMixesInstance,
  mwmDevEdjingBackendMixesInstance,
  mwmEdjingTidalAnalyticsInstance,
};
