<template>
  <v-row v-if="isMobile" no-gutters class="top--content">
    <v-col cols="12">
      <v-row no-gutters justify="center">
        <img class="top--icon" src="static/01_top_icon.png" />
      </v-row>
      <v-row no-gutters justify="center">
        <img class="top--logo" src="static/01_top_logo.png" />
      </v-row>
      <v-row no-gutters justify="center">
        <span class="top--title" v-html="titleText" />
      </v-row>
      <v-row no-gutters justify="center">
        <v-col cols="auto">
          <div>
            <img @click="openStore" class="top--store-apple" src="static/all_badge_appstore.png" >
          </div>
        </v-col>
        <v-col cols="auto">
          <img @click="openStore" class="top--store-google" src="static/all_badge_playstore.png" >
        </v-col>
      </v-row>
      <v-row no-gutters justify="center">
        <img class="top--image" src="static/01_top_ipad_pro.png" />
      </v-row>
    </v-col>
  </v-row>
  <v-row v-else-if="!isMobile" no-gutters class="top--content">
    <v-col cols="12">
      <v-row no-gutters justify="center">
        <img class="top--icon" src="static/01_top_icon.png" />
      </v-row>
      <v-row no-gutters justify="center">
        <img class="top--logo" src="static/01_top_logo.png" />
      </v-row>
      <v-row no-gutters justify="center">
        <span class="top--title" v-html="titleText" />
      </v-row>
      <v-row no-gutters justify="center">
        <v-col cols="auto">
          <div class="top--store-apple-container">
            <a href="https://apps.apple.com/us/app/id493226494" target="_blank">
              <img class="top--store-apple" src="static/all_badge_appstore.png" >
            </a>
          </div>
        </v-col>
        <v-col cols="auto">
          <a href="https://play.google.com/store/apps/details?id=com.edjing.edjingdjturntable" target="_blank">
            <img class="top--store-google" src="static/all_badge_playstore.png" >
          </a>
        </v-col>
      </v-row>
      <v-row no-gutters justify="center">
        <img class="top--image" src="static/01_top_ipad_pro.png" />
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  components: {

  },
  data: () => ({
    titleText: 'The #1 App to mix on your iPhone, iPad,<br /> Android phones & tablets'
  }),
  methods: {
    openStore () {
      const url = 'https://edjing-website-landing-page.onelink.me/Yx8k/100d32e5'
      const win = window.open(url, '_blank')
      win.focus()
    }
  },
  computed: {
    ...mapGetters([
      'isMobile'
    ])
  },
  mounted () {

  }
};
</script>

<style scoped lang="scss">
@media (max-width: 960px) {
  .top--content {
    // height: 80vh!important;
  }
  .top--icon {
    margin-top: 2rem!important;
    width: 130px!important;
    height: 130px!important;
  }
  .top--title {
    font-size: 18px!important;
    line-height: 22px!important;
  }
  .top--logo {
    width: 103px!important;
    height: 29px!important;
  }
  .top--store-apple {
    height: 43px!important;
    width: 130px!important;
  }
  .top--store-google {
    height: 43px!important;
    width: 145px!important;
  }
  .top--image {
    width: 100%!important;
    height: 100%!important;
    -webkit-mask-image:-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0)));
    mask-image: linear-gradient(to bottom, rgba(0,0,0,1), rgba(0,0,0,0));
  }
}

.top--content {
  padding-top: 4rem;
  background-color: black;
  background-image: url("/static/01_top_gradient_blue.png"), url("/static/01_top_gradient_red.png");
  background-position: center;
  background-size: cover;
}
.top--icon {
  margin-top: 5rem;
  width: 200px;
  height: 200px;
}
.top--title {
  font-family: 'Inter', sans-serif;
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-align: center;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  letter-spacing: -1.2px;
  color: rgba(255, 255, 255, 0.8);
}
.top--logo {
  width: 166px;
  height: 48px;
}
.top--store-apple-container {
  height: 64px;
  width: 216px;
  margin-right: 1rem;
}
.top--store-apple {
  height: 64px;
  width: 191px;
  margin-left: 25px;
  cursor: pointer;
}
.top--store-google {
  height: 64px;
  width: 216px;
  margin-left: 1rem;
  cursor: pointer;
}
.top--image {
  margin-top: 2rem;
  width: 100%;
}
</style>
